<template>
  <div class="jdsearch">
    <div class="jdsearchBox">
      <van-nav-bar left-arrow @click-left="$router.back(-1)" :border="false">
        <template #title>
          <van-search
            v-model="pages.keyWords"
            :placeholder="placeHolder"
            background="none"
            :clearable="false"
            @search="queryjdGoodsListFn"
          />
        </template>
        <template #right>
          <van-button @click="queryjdGoodsListFn" color="#FF4242"
            >搜索</van-button
          >
        </template>
      </van-nav-bar>
      <div class="shopFixed">
        <div class="searchFixed">
          <div class="shopListType">
            <van-tabs
              v-model="active"
              :ellipsis="false"
              title-active-color="#333"
              title-inactive-color="#333"
              color="#ee0a24"
              @click="salesChangeFn"
            >
              <van-tab title="销量" name="sales"></van-tab>
              <van-tab name="price" title="价格"> </van-tab>
            </van-tabs>
            <img
              :src="OSS_URL + '/shop/sort.png'"
              v-if="priceSortActive == ''"
            />
            <img
              :src="OSS_URL + '/shop/sort_top.png'"
              v-if="priceSortActive == 'ASC'"
            />
            <img
              :src="OSS_URL + '/shop/sort_bottom.png'"
              v-if="priceSortActive == 'DESC'"
            />
          </div>
          <div class="moneySearch">
            <van-tabs
              v-model:active="moneyActive"
              @click="setPriceIntervalIdFn"
            >
              <van-tab title="" name="" class="active moneyActive"></van-tab>
              <van-tab
                :title="item.priceIntervalName"
                v-for="item in conditionObject?.priceIntervalList"
                :key="item.priceIntervalId"
                :name="item.priceIntervalId"
              ></van-tab>
            </van-tabs>
          </div>
        </div>
      </div>
    </div>
    <div class="shoplistBox" v-if="nullList">
      <van-list
        v-model:loading="loading"
        :immediate-check="false"
        :finished="finished"
        @load="onloadFn"
      >
        <shoplist :shopList="jdGoodsList" :color="'ff4242'"></shoplist>
      </van-list>
    </div>
    <div v-else class="nullList">
      <img :src="OSS_URL + '/null.png'" />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  onActivated,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import shoplist from "@/components/shoplist";
import { jdSearchPageCondition, jdGoodsList } from "@/api/shop";
export default defineComponent({
  components: {
    shoplist,
  },
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    const state = reactive({
      OSS_URL: process.env.VUE_APP_OSS_URL,
      priceSortActive: "",
      conditionObject: null,
      moneyActive: "",
      active: "",
      loading: false,
      finished: false,
      placeHolder: $route.query.placeholder,
      jdGoodsList: [],
      pages: {
        keyWords: $route.query.text,
        oneCateId: null,
        page: 1,
        pageSize: 10,
        priceIntervalId: null,
        sortPrice: null,
        sortSales: "DESC",
        threeCateId: $route.query.id,
        twoCateId: null,
      },
      nullList: true,
    });
    const jdSearchPageConditionFn = async () => {
      let rs = await jdSearchPageCondition({ keyWords: "" });
      state.conditionObject = rs.data;
      jdGoodsListFn();
    };

    const jdGoodsListFn = async () => {
      let rs = await jdGoodsList(state.pages);
      state.jdGoodsList = [...state.jdGoodsList, ...rs.data.list];
      if (state.jdGoodsList.length > 0) {
        state.nullList = true;
      } else {
        state.nullList = false;
      }
      if (state.pages.page >= rs.data.totalpage) {
        state.finished = true;
      } else {
        state.loading = false;
      }
    };
    const queryjdGoodsListFn = (e) => {
      if (state.pages.keyWords.trim()) {
        $router.push({
          path: "/jdshop/jdtwosearch",
          query: {
            text: state.pages.keyWords,
          },
        });
      }
    };
    const onloadFn = () => {
      setTimeout(() => {
        state.pages.page = state.pages.page + 1;
        jdGoodsListFn();
      }, 1500);
    };

    const setPriceIntervalIdFn = (id) => {
      if (state.pages.priceIntervalId == id) {
        state.moneyActive = "";
        state.pages.priceIntervalId = "";
      } else {
        state.pages.priceIntervalId = id;
      }
      state.pages.page = 1;
      state.jdGoodsList = [];
      state.loading = false;
      state.finished = false;
      jdGoodsListFn();
    };

    const salesChangeFn = (id) => {
      if (id == "sales") {
        state.pages.sortSales = "DESC";
        state.pages.sortPrice = null;
        state.priceSortActive = "";
      } else if (id == "price") {
        state.pages.sortSales = null;
        if (state.priceSortActive == "DESC") {
          state.pages.sortPrice = "ASC";
          state.priceSortActive = "ASC";
        } else {
          state.pages.sortPrice = "DESC";
          state.priceSortActive = "DESC";
        }
      }
      state.pages.page = 1;
      state.jdGoodsList = [];
      state.loading = false;
      state.finished = false;
      jdGoodsListFn();
    };

    $router.afterEach((to, from) => {
      if (from.path == "/jdshop/goodsDetail") {
        to.meta.isUseCache = false;
      } else {
        to.meta.isUseCache = true;
      }
    });
    onActivated(() => {
      if ($route.meta.isUseCache) {
        (state.priceSortActive = ""),
          (state.conditionObject = null),
          (state.moneyActive = ""),
          (state.active = ""),
          (state.loading = false),
          (state.finished = false),
          (state.placeHolder = $route.query.placeholder),
          (state.jdGoodsList = []),
          (state.pages = {
            keyWords: $route.query.text,
            oneCateId: null,
            page: 1,
            pageSize: 10,
            priceIntervalId: null,
            sortPrice: null,
            sortSales: "DESC",
            threeCateId: $route.query.id,
            twoCateId: null,
          });
        jdSearchPageConditionFn();
      }
    });

    return {
      jdSearchPageConditionFn,
      setPriceIntervalIdFn,
      jdGoodsListFn,
      queryjdGoodsListFn,
      onloadFn,
      salesChangeFn,
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.jdsearch {
  .jdsearchBox {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .van-nav-bar {
    .van-button {
      height: 30px;
      border-radius: 4px;
      border: 1px solid #ff4e4e;
      color: #ff4e4e;
      font-size: 14px;
    }
    ::v-deep(.van-nav-bar__left) {
      left: -10px;
      top: -5px;
      .van-icon {
        font-size: 22px;
        color: #333;
        padding-top: 4px;
      }
    }
    ::v-deep(.van-nav-bar__title) {
      width: 273px;
      margin: 0 30px;
      max-width: 100%;
      .van-search {
        height: 30px;
        .van-search__content {
          background: #f2f2f2;
          border-radius: 8px;
        }
        .van-icon {
          color: #333;
        }
      }
    }
  }
  ::v-deep(.van-nav-bar) {
    color: #333;
  }
  .shopSearch {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    .van-search {
      width: 282px;
    }
    .van-button {
      height: 30px;
      border-radius: 15px;
      border: 1px solid #ff4e4e;
      color: #ff4e4e;
      font-size: 14px;
    }
  }
  .typeChoose {
    color: #fff;
    .typeItem {
      ::v-deep(.van-tabs) {
        background: #fff;
        .van-tab {
          font-size: 14px;
        }
        .van-tabs__line {
          bottom: 20px;
        }
        .van-tabs__nav {
          background: none;
        }
      }
      display: flex;
      .allType {
        width: 78px;
        text-align: center;
        font-size: 14px;
        div {
          margin-top: 13px;
          .van-icon {
            vertical-align: middle;
          }
          span {
            position: relative;
            bottom: 1px;
          }
        }
      }
    }
  }
  .searchFixed {
    background: #fff;
    height: 80px;
    .shopListType {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      width: 100%;
      position: relative;
      ::v-deep(.van-tabs) {
        .van-tabs__nav {
          width: 120px;
        }
        .van-tab__text {
          // color: #333333;
          font-weight: 500;
        }
      }
      ::v-deep(.van-tabs__line) {
        width: 16px;
        bottom: 20px;
      }
      img {
        position: absolute;
        top: 16px;
        left: 115px;
      }
      ::v-deep(.priceSort) {
        display: flex;
        .priceSort-item {
          height: 0px;
          padding-left: 2px;
          padding-top: 1px;
        }
        img {
          position: relative;
          left: -3px;
          top: 2px;
        }
      }
    }
    .moneySearch {
      width: 100%;
      background: #fff;
      padding: 0px 10px;
      ::v-deep(.van-tabs--line .van-tabs__wrap) {
        .van-tabs__line {
          display: none;
        }
        .van-tab {
          padding: 5px;
          flex: none;
          &:nth-child(1) {
            position: absolute;
            top: 12px;
            left: 10px;
            opacity: 0;
          }
          .van-tab__text {
            font-size: 12px;
            color: #333;
            padding: 2px 5px;
            background: #eaeaea;
            border-radius: 4px;
          }
        }
        ::v-deep(.van-tab:nth-child(1)) {
          position: absolute;
          opacity: 0;
        }
        .van-tab--active {
          .van-tab__text {
            background: #ff4e4e;
            color: #fff;
            top: 0px;
            left: 0px;
          }
        }
      }
    }
  }
  .nullList {
    margin-top: 160px;
    text-align: center;
    img {
      width: 138px;
      height: 163px;
    }
  }
  .shoplistBox {
    margin-top: 8px;
  }
}
</style>
